import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '@/utils/persist/local-storage';
import { IDrawState } from '@/modules/Drawing/core/DrawCanvas';


export enum BRUSH_TYPE {
  PEN = 'pen',
  ERASER = 'eraser',
  LINE = 'line',
}

export interface IDrawingBoardState {
  drawState: IDrawState;
  toolVisible: boolean;
  hadPencil: boolean;
}

const initialState: IDrawingBoardState = {
  drawState: LocalStorage.getStorage('tingxie-drawdata')||{
    color: '#000000',
    type: BRUSH_TYPE.PEN,
    lineWidth: 2,
    eraserWidth: 3,
    clear: false,
  },
  toolVisible: false,
  hadPencil: false,
};

export const storageDrawingData = (data:IDrawState)=>{
  LocalStorage.setStorage('tingxie-drawdata', data);
};

export const drawingBoardSlice = createSlice({
  name: 'drawingBoard',
  initialState,
  reducers: {
    /**
     * @param state
     * @param action
     * @returns
     */
    setDrawingState: (state, action: { payload: IDrawState; }) => {
      state.drawState = { ...action.payload };
      storageDrawingData(state.drawState);
    },
    setDrawingImageData: (state, action: { payload: string; }) => {
      state.drawState.imageData = action.payload;
      storageDrawingData(state.drawState);
    },
    doDrawingClear: (state, action: { payload: boolean; }) => {
      state.drawState.clear = action.payload;
    },
    setToolVisible: (state, action: { payload: boolean; }) => {
      state.toolVisible = action.payload;
    },
    setHadPencil: (state, action: { payload: boolean; }) => {
      state.hadPencil = action.payload;
    },
    toggleBrushType: (state)=>{
      const brushType = state.drawState.type === BRUSH_TYPE.PEN ? BRUSH_TYPE.ERASER : BRUSH_TYPE.PEN;
      state.drawState = {...state.drawState, type: brushType};
    }
  },
});
export const { setDrawingState, doDrawingClear, setToolVisible, setHadPencil, toggleBrushType, setDrawingImageData } =
  drawingBoardSlice.actions;
