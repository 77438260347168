/**
 * 端控制整体文字大小
 * 如果文字大小等比缩放, 直接用rem即可
 * 如果rem不够用,在varSets 中写下需要的变量和对应不同缩放下的字体大小即可
 */

import { onFontSizeChange } from './jsBirdge/listen';
import { LocalStorage } from './persist/local-storage';

const fontSizeKey = 'font_size';

const varSets: Array<{ [key: string]: string; }> = [{
  '--dauyan_font_size_header': '20px',
}, {
  '--dauyan_font_size_header': '22px',
}, {
  '--dauyan_font_size_header': '25px',
}, {
  '--dauyan_font_size_header': '26px',
}, {
  '--dauyan_font_size_header': '26px',
}, {
  '--dauyan_font_size_header': '26px',
}];

export const rootSize = ['8px', '10px', '12px', '14px', '16px', '18px'];

let styleDom: HTMLStyleElement | null = null;

const generateStyleString = (obj: { [key: string]: string; }) => {
  const strQueue = [':root{'];

  Object.keys(obj).forEach((key) => {
    const v = obj[key];

    strQueue.push(`${key}:${v};`);
  });

  strQueue.push('}');
  return strQueue.join('');
};

export let _step = -1;

export const setFontSize = (step: number) => {

  if (rootSize.length > step && rootSize.length > step) {
    _step = step;
    if (!styleDom) {
      styleDom = document.createElement('style');
      document.head.appendChild(styleDom);
    }
    styleDom.innerHTML = generateStyleString(varSets[step]);
    document.getElementsByTagName('html')[0].style.fontSize = rootSize[step];
    LocalStorage.setStorage(fontSizeKey, step);
  }
};

export const initFontSize = () => {
  onFontSizeChange((data: any) => {
    setFontSize(Math.max(0, Math.min(5, data.step - 1)));
  });
  if (LocalStorage.hasKey(fontSizeKey)) {
    setFontSize(LocalStorage.getStorage(fontSizeKey));
  } else {
    setFontSize(2);
  }
};

export const getCurrentFontSizeStep = (): number => {
  return _step + 1;
};

export const getCurrentFontSizeNumber = (): number => {
  return parseInt(rootSize[_step]);
};

export const getCurrentFontSize = () => {
  return rootSize[_step];
};

export const getFontSizeNumberOfStep = (step: number) => {
  if (step >= 0 && step < rootSize.length) {
    return parseInt(rootSize[step]);
  }
  return getCurrentFontSizeNumber();
};
