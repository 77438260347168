import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { logError } from '@/utils/logger/arms-config';
import {
  getPaperInfo,
  listeningSaveRecord,
  getRecordInfo,
  ListeningCollateAnswer,
  getRecordAnswerInfo,
  listenSaveRecord
} from '@/api/listen';
import type { MemberType } from '@/modules/Listening/core/interface';
import { getExam, saveExam } from '@/api/common';

/**
 * 听力题目类型
 */
export enum ILQuestionTypeEnum {
  /** 单选 */
  MC = 1,
  /** 多选 */
  MS = 2,
  /** 表格填空 */
  TA = 3,
  /** 简答题 */
  SA = 4,
  /** 句子填空 */
  SFI = 5,
  /** 图片填空 */
  PT = 6,
  /** 图片匹配 */
  PTM = 7,
  /** 匹配题 */
  DM = 8,
  /** 自定义多选 */
  CMS = 9,
  /** 表格匹配 */
  TADM = 10
}

export interface ILQuestionDetail {
  // global id
  id: number;
  // question number
  number: number;
  // 题目内容
  title: string;
  option: any[];

  // 题目解析
  analyze: Array<{ type: string; content: string; location: any; }>;

  // 定位词
  locating_words: Array<{
    locating_word: string;
    syn_ary: Array<{
      synonym: string;
      target_sen: Array<{
        index: number;
        length: number;
        lyc_index: number;
        target_sen: string;
      }>;
    }>;
  }>;

  // 答案句
  answer_sentences: {
    lyc_index: number[];
    start_time: number;
    end_time: number;
  };

  // 本地对应的音频时间
  start_time: number;
  end_time: number;

  display_answer: string;
  ai_analyze: string;
}

export interface ILQuestion {
  analyze: string;
  collect: string;
  collect_option: any;
  desc: string;
  group_id: 1;
  img_url: string;
  list: ILQuestionDetail[];
  table: any;
  type: ILQuestionTypeEnum;
  member_type?: MemberType;
  key?: string;
  questionId?: string | number;
  editor?: boolean;
  primaryKey?: string;
}

/**
 * 听力试卷的类型
 */
export interface ILPaperInfo {
  paper_id: number;
  title: string;
  content: any;
  topic: string[];
  type: string;
  unit: string;
  question: ILQuestion[];
  question_number: number[];
  analyze: string;
  file_url: string;
  file_json_url: string;
}

export type ILRecordInfo = any

export interface ILRecordAnswerInfoListItem {
  answer: string[];
  group_id: number;
  question_id: number;
  result: number;
  sub_answer: string[];
  type: number;
}

export interface lRecordAnswerInfo {
  list: ILRecordAnswerInfoListItem[];
  paper_id: number;
  record_id: number;
}

/**
 * 听力的状态管理
 */
export interface ILQuestionState {
  /** 听力数据加载状态 */
  lLoading: boolean;
  /** 听力试卷详情 */
  lPaperInfo: ILPaperInfo | null;
  /** 练习记录 */
  lRecordInfo: ILRecordInfo;
  /** 答案 */
  lRecordAnswerInfo: lRecordAnswerInfo | null;
  /** 驱动更新页面 */
  lForceUpdate: number;
  /** 笔记详情 */
  lNoteInfo: {
    note: any[];
    group: any[];
    note_group: any[];
  };
}

const initialState: ILQuestionState = {
  lLoading: false,
  lPaperInfo: null,
  lRecordInfo: null,
  lRecordAnswerInfo: null,
  lForceUpdate: 0,
  lNoteInfo: {
    note: [],
    group: [],
    note_group: []
  }
};

/**
 * 初始化阅读理解的详情
 */
export interface ILInfoQuery {
  id: number;
  type?: string;
  record_id?: string;
  question?: string;
}
export const initListen = createAsyncThunk('listening/initListen', async (
  params: ILInfoQuery,
  { getState, rejectWithValue }
) => {
  try {

    const { id, record_id: record_id_str, question: question_str, type: type_str } = params;
    // 0. 练习记录 id
    const record_id = record_id_str ? Number(record_id_str) : 0;
    const question = question_str || '';
    const type = type_str ? Number(type_str) : 0;
    // 1. 获取阅读理解的详情
    const info = await getPaperInfo(id, !type ? [] : [type]);
    // 2. 如果是一张新试卷，那么就要先创建一下练习记录
    let recordId = null;
    if (record_id === 0) {
      const res = await listeningSaveRecord({
        paper_id: id,
        record_id: record_id,
        question: question,
        type: type
      });
      recordId = res.data.id;
    } else {
      recordId = record_id;
    }
    // 3. 获取联系记录的详情
    const record = await getRecordInfo(recordId);
    if (record.data.paper_id !== id) {
      const s = `当前Record=[${recordId}]属于Paper[${record.data.paper_id}]不属于Paper[${id}]`;
      console.error(s);
      logError(s);
      // TODO: 这里暂时用-1表示资源不匹配，随后统一定义前端错误码
      return rejectWithValue({ code: -1, msg: s });
    }
    const status = record.data.status;

    // 4. 获取笔记缓存
    const noteInfo = await getExam({ record_id: recordId, type: 1 });

    // 返回值
    let data = {
      lPapgerInfo: info.data,
      lRecordInfo: record.data,
      lNoteInfo: {
        note: noteInfo?.data?.note || [],
        group: noteInfo?.data?.group || [],
        note_group: noteInfo?.data?.note_group || []
      }
    } as any;
    // 如果状态是 2 说明已经提交了，那么就要获取答案信息
    if (status === 2) {
      const recordAnswerInfo = await getRecordAnswerInfo(record_id);
      data = {
        ...data,
        lRecordAnswerInfo: recordAnswerInfo.data
      };
    }
    return data;
  } catch (e) {
    return rejectWithValue({
      code: get(e, 'code'),
      msg: get(e, 'msg'),
    });
  }
});

export const fetchPaperInfo = createAsyncThunk('listening/fetchPaperInfo', async (
  params: ILInfoQuery,
  { getState, rejectWithValue }
) => {
  const { id } = params;
  // 1. 获取阅读理解的详情
  const info = await getPaperInfo(id, []);

  const data = {
    lPapgerInfo: info.data,
  } as any;
  return data;
});


/**
 * 提交的答案
 */
export const submitCollateListenAnswer = createAsyncThunk('listening/submitCollateAnswer', async (
  params: any,
  { getState, rejectWithValue }) => {
  const state = getState() as any;
  const readingComprehension = state.listening.lRecordInfo;
  const record_id = readingComprehension.id;
  // 提交答案
  await ListeningCollateAnswer({ record_id, list: params });
});

export const fetchListeningSaveRecord = createAsyncThunk('listening/fetchListeningSaveRecord', async (
  params: any,
  { getState, rejectWithValue }) => {
  const state = getState() as any;
  const { lPaperInfo, lRecordInfo } = state.listening;
  const paper_id = lPaperInfo.paper_id;
  const record_id = lRecordInfo.id;
  await listenSaveRecord({
    paper_id,
    record_id: record_id,
    question: params
  });
});

export const fetchListeningSaveRecordEssay = createAsyncThunk('listening/fetchListeningSaveRecord', async (
  params: any,
  { getState, rejectWithValue }) => {
  const state = getState() as any;
  const { lPaperInfo, lRecordInfo } = state.listening;
  const paper_id = lPaperInfo.paper_id;
  const record_id = lRecordInfo.id;
  await listenSaveRecord({
    paper_id,
    record_id: record_id,
    essay: params
  });
});

// 保存笔记
export const fetchExamSave = createAsyncThunk('listening/fetchExamSave', async (payload: any, { getState, rejectWithValue }) => {
  const state = getState() as any;
  const { lRecordInfo } = state.listening;
  const record_id = lRecordInfo.id;
  await saveExam({
    record_id: record_id,
    type: 1,
    note: payload.note,
    group: payload.group,
    note_group: payload.note_group
  });
});

export const listeningSlice = createSlice({
  name: 'listening',
  initialState,
  reducers: {
    forceUpdateListening: (state) => {
      state.lForceUpdate = state.lForceUpdate + 1;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(initListen.pending, (state, action) => {
        state.lLoading = true;
      })
      .addCase(initListen.fulfilled, (state, action) => {
        if (action.payload) {
          state.lPaperInfo = action.payload.lPapgerInfo;
          state.lRecordInfo = action.payload.lRecordInfo;
          state.lRecordAnswerInfo = action.payload.lRecordAnswerInfo;
          state.lNoteInfo = action.payload.lNoteInfo;
          state.lLoading = false;
        }
      })
      .addCase(fetchPaperInfo.pending, (state) => {
        state.lLoading = true;
      })
      .addCase(fetchPaperInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.lPaperInfo = action.payload.lPapgerInfo;
        }
      })
      .addCase(submitCollateListenAnswer.pending, (state) => {
        state.lLoading = true;
      })
      .addCase(submitCollateListenAnswer.fulfilled, (state, action) => {
        state.lForceUpdate = state.lForceUpdate + 1;
      })
      .addCase(submitCollateListenAnswer.rejected, (state) => {
        state.lLoading = false;
      });
  }
});

export default listeningSlice;
export const ListeningActions = listeningSlice.actions;