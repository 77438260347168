import { GlobalEventProxy } from '@/utils/event-proxy';
import { logInfo } from '@/utils/logger/arms-config';
import { generator as uiEventGenerator } from './ui';
import { generator as systemEventGenerator } from './system';

const eventProxy = new GlobalEventProxy();

export const initJsBrdgeListener = () => {
  window.addEventListener('dyy-bridge-send', (event: unknown) => {
    const detail = (event as any).detail;
    if (typeof detail === 'object' && !!detail && typeof (detail as any).action === 'string') {
      console.log('events', (detail as any).action);
      logInfo({ type: 'native_event', action: (detail as any).action });
      eventProxy.publishEvent((detail as any).action, detail.data);
    }
  });
};


const listener = {
  ...uiEventGenerator(eventProxy),
  ...systemEventGenerator(eventProxy),
} as ReturnType<typeof uiEventGenerator> & ReturnType<typeof systemEventGenerator>;

export const onFontSizeChange = listener.onFontSizeChange;
export const onKeyboardHeightChange = listener.onKeyboardHeightChange;
export const onTouchPoint = listener.onTouchPoint;
export const onPencilContactChange = listener.onPencilContactChange;
export const onPencilSwitchChange = listener.onPencilSwitchChange;
export const onUpdateUserInfo = listener.onUpdateUserInfo;
export const onUpdateCookie = listener.onUpdateCookie;

// (window as any).mockEvent = (name: string, value: any) => {
//   const event = new CustomEvent('dyy-bridge-send', {
//     detail: {
//       action: name,
//       data: value,
//     },
//   });
//   window.dispatchEvent(event);
// };
